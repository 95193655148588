<template>
  <main-container>
    <!-- TOP -->
    <template v-slot:header>
      <button class="a-main-btn" @click="settings.addModal = true">
        Agregar
      </button>
      <div class="a-search-container">
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
        <button
          class="a-icon-btn"
          @click="settings.reportModal = true"
          v-if="settings.chartData.chartOptions.labels.length > 0"
        >
          <div>
            <img :src="require('@/assets/icons/report.svg')" height="24" />
          </div>
        </button>
      </div>
    </template>

    <!-- Table -->
    <DataTable
      :value="dataset"
      :paginator="true"
      :rows="7"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="['username', 'userType.name', 'id']"
    >
      <!-- Id field -->
      <Column field="id" header="Id." sortable headerStyle="width: 5rem" />
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      >
      </Column>

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailDataset(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </main-container>

  <Dialog
    header="Crear paso"
    v-model:visible="settings.addModal"
    v-if="settings.addModal"
    :modal="true"
    :breakpoints="{ '960px': '85vw' }"
    :style="{ width: '90vw' }"
  >
    <Create @onSuccess="datasetAdded" @onExit="settings.addModal = false" />
  </Dialog>

  <Dialog
    header="Estadísticas"
    v-model:visible="settings.reportModal"
    v-if="settings.reportModal"
    :modal="true"
    :breakpoints="{ '960px': '85vw' }"
    :style="{ width: '80vw' }"
  >
    <div id="a-chart-container">
      <h3>
        <span class="a-id-badge">{{ dataset.length }}</span>
        Usuarios registrados
      </h3>
      <apexchart
        :options="settings.chartData.chartOptions"
        :series="settings.chartData.series"
      ></apexchart>
    </div>
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '85vw' }"
    :style="{ width: '80vw' }"
  >
    <template #header>
      <h3>
        Usuario
        <span class="a-id-badge" v-if="settings.selected != null">{{
          settings.selected.id
        }}</span>
      </h3>
    </template>
    <DetailOrEdit :item="settings.selected" :isEdit="settings.isEdit" />
  </Dialog>
</template>

<script>
import MainContainer from "../components/templates/MainContainer.vue";
import Create from "@/components/user/NewUser.vue";
import DetailOrEdit from "@/components/user/DetailUser.vue";
import UserAPI from "@/services/users.js";
import { FilterMatchMode } from "primevue/api";
export default {
  components: { MainContainer, Create, DetailOrEdit },
  name: "Users",
  data() {
    return {
      dataset: [],
      fields: [
        {
          field: "username",
          header: "Usuario",
        },
        {
          field: "userType.name",
          header: "Tipo",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        addModal: false,
        detailModal: false,
        reportModal: false,
        selected: null,
        chartData: {
          series: [
            {
              data: [],
            },
          ],
          chartOptions: {
            labels: [],
            chart: {
              type: "bar",
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
              },
            },
            dataLabels: {
              enabled: true,
              textAnchor: "start",
              style: {
                colors: ["#fff"],
              },
              offsetX: 0,
              dropShadow: {
                enabled: true,
              },
            },
          },
        },
      },
    };
  },
  methods: {
    getDataset() {
      UserAPI.index()
        .then((response) => {
          this.dataset = response.data;
          this.getChartData();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getBuilders();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    getChartData() {
      this.settings.chartData.labels = [];
      this.settings.chartData.series[0].data = [];
      let counters = [];
      this.dataset.forEach((element) => {
        if (counters == null) {
          counters.push({
            name: element.userType.name,
            quantity: 1,
          });
        } else {
          let found = false;
          counters.forEach((el, index) => {
            if (el.name == element.userType.name) {
              found = true;
              counters[index].quantity = el.quantity + 1;
            }
          });

          if (!found) {
            counters.push({
              name: element.userType.name,
              quantity: 1,
            });
          }
        }
      });

      counters.forEach((count) => {
        this.settings.chartData.chartOptions.labels.push(count.name);
        this.settings.chartData.series[0].data.push(count.quantity);
      });
      console.log(counters);
    },
    datasetAdded() {
      this.settings.addModal = false;
      this.getDataset();
      this.$toast.add({
        severity: "success",
        detail: "Se ha añadido un nuevo objeto",
        life: 3000,
      });
    },
    detailDataset(data) {
      this.settings.selected = data;
      this.settings.detailModal = true;
    },
    onStateChange(data) {
      UserAPI.updateState(data)
        .then(() => {
          this.$toast.add({
            severity: "success",
            detail: "Se ha actualizado el estado de la información.",
            life: 3000,
          });
          this.getDataset();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.onStateChange(data);
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
  mounted() {
    this.getDataset();
  },
};
</script>

<style>
.a-search-container .a-icon-btn img {
  filter: brightness(0) invert(1);
}
#a-chart-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.apexcharts-canvas,
.vue-apexcharts {
  width: 100% !important;
}
</style>
